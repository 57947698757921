.line-clamp,
.filter-bar.com .col-filter-bar,
.item-event.com h3,
.item-event.com .desc,
.item-podcast.com h3,
.item-podcast.com .desc,
.item-recommend.com h3,
.item-recommend.com .desc,
.map-filter.com .--with-icons label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.line-clamp.--clamp-2,
.filter-bar.com .col-filter-bar.--clamp-2,
.item-event.com h3.--clamp-2,
.item-event.com .desc.--clamp-2,
.item-podcast.com h3.--clamp-2,
.item-podcast.com .desc.--clamp-2,
.item-recommend.com h3.--clamp-2,
.item-recommend.com .desc.--clamp-2,
.map-filter.com .--with-icons label.--clamp-2 {
  -webkit-line-clamp: 2;
}
.line-clamp.--clamp-3,
.filter-bar.com .col-filter-bar.--clamp-3,
.item-event.com h3.--clamp-3,
.item-event.com .desc.--clamp-3,
.item-podcast.com h3.--clamp-3,
.item-podcast.com .desc.--clamp-3,
.item-recommend.com h3.--clamp-3,
.item-recommend.com .desc.--clamp-3,
.map-filter.com .--with-icons label.--clamp-3 {
  -webkit-line-clamp: 3;
}
.line-clamp.--clamp-4,
.filter-bar.com .col-filter-bar.--clamp-4,
.item-event.com h3.--clamp-4,
.item-event.com .desc.--clamp-4,
.item-podcast.com h3.--clamp-4,
.item-podcast.com .desc.--clamp-4,
.item-recommend.com h3.--clamp-4,
.item-recommend.com .desc.--clamp-4,
.map-filter.com .--with-icons label.--clamp-4 {
  -webkit-line-clamp: 4;
}
.line-clamp.--clamp-5,
.filter-bar.com .col-filter-bar.--clamp-5,
.item-event.com h3.--clamp-5,
.item-event.com .desc.--clamp-5,
.item-podcast.com h3.--clamp-5,
.item-podcast.com .desc.--clamp-5,
.item-recommend.com h3.--clamp-5,
.item-recommend.com .desc.--clamp-5,
.map-filter.com .--with-icons label.--clamp-5 {
  -webkit-line-clamp: 5;
}
h2 {
  color: #555351;
}
h3 {
  color: #7d7b79;
}
ul.plain li,
.aside-tips.com ul li,
.properties.com li,
.tag-list.com li,
.main-nav ul li,
.tab-nav ul li,
nav ul li,
.slider ul li,
.social-nav ul li,
ul.unstyled li {
  padding-left: 0;
  margin: 0;
}
ul.plain li:before,
.aside-tips.com ul li:before,
.properties.com li:before,
.tag-list.com li:before,
.main-nav ul li:before,
.tab-nav ul li:before,
nav ul li:before,
.slider ul li:before,
.social-nav ul li:before,
ul.unstyled li:before {
  display: none;
}
table {
  margin: 0 0 2em;
  border: 0;
  clear: both;
  width: 100%;
  box-sizing: border-box;
  font-weight: ;
}
table img {
  vertical-align: middle;
}
table th,
table td {
  text-align: left;
}
table th {
  background: #ada295;
  color: #fff;
  font-weight: 600;
  padding: 10px 13px;
  font-size: 20px;
  font-family: Noto serif, serif;
  border: 1px solid #707070;
}
table td {
  border: 1px solid #707070;
  color: #a8a8a8;
  font-size: 13px;
  padding: 10px 10px;
}
table td:first-child {
  color: #7d7b79;
  font-size: 16px;
  font-weight: 600;
}
table.--bigger-cells td {
  padding-top: 20px;
  padding-bottom: 20px;
}
table.--smaller-cells td {
  padding-top: 10px;
  padding-bottom: 10px;
}
table.--smaller-th th {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 819.98px) {
  table th,
  table td {
    padding: 5px 2px;
    font-size: 14px;
  }
}
.form-control {
  height: 38px;
  font-size: 16px;
  line-height: 1.3;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 19px;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  color: #333;
  border: none;
  box-shadow: 0px 13px 26px rgba(173,162,149,0.302);
}
.form-control:focus {
  border-color: #8a8a8a;
}
.form-control::placeholder {
  color: #bebebe;
}
.form-control.error {
  border-color: #f1122c;
  border-width: 3px;
}
.form-control.form-control-sm {
  height: calc(2.375em + 2px);
  font-size: 16px;
  line-height: 1.3;
}
.form-control.form-control-lg {
  font-size: 20px;
  line-height: 1.3;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url("../img/svg/select-arrow.svg") no-repeat right 21px center;
  background-size: 28px 19px;
}
select::-ms-expand {
  display: none;
}
select:disabled {
  background: transparent !important;
  border: none;
}
select,
option {
  cursor: pointer;
}
label {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 10px;
}
.btn,
a.btn,
.header .easy-autocomplete-container .btns .btn {
  height: 54px;
  font-size: 18px;
  line-height: 1.3;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-left: 1.8em;
  padding-right: 1.8em;
  border-radius: 27px;
  display: inline-block;
  vertical-align: middle;
  background: #7a6e60;
  color: #fff;
  font-family: Noto serif, serif;
  font-weight: ;
  text-align: center;
  text-decoration: none;
  position: relative;
  outline: none;
  transition: all 0.3s;
  border: none;
  white-space: normal;
  cursor: pointer;
  border: 0;
}
.btn span,
a.btn span,
.header .easy-autocomplete-container .btns .btn span {
  display: inline-block;
  width: 100%;
}
.btn.small,
a.btn.small,
.header .easy-autocomplete-container .btns .btn.small {
  height: 38px;
  font-size: 15px;
  line-height: 1.3;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 19px;
  letter-spacing: -0.02em;
  font-weight: bold;
}
.btn.white,
a.btn.white,
.header .easy-autocomplete-container .btns .btn.white {
  background: #fff;
  color: #555351;
}
.btn.white.alpha,
a.btn.white.alpha,
.header .easy-autocomplete-container .btns .btn.white.alpha {
  background: rgba(255,255,255,0.7);
}
.btn.white.alpha:hover,
a.btn.white.alpha:hover,
.header .easy-autocomplete-container .btns .btn.white.alpha:hover {
  background: #fff;
}
.btn.golden,
a.btn.golden,
.header .easy-autocomplete-container .btns .btn.golden {
  background-color: #cbaf65;
}
.btn.golden:hover,
a.btn.golden:hover,
.header .easy-autocomplete-container .btns .btn.golden:hover {
  background-color: #7a6e60;
}
@media (max-width: 819.98px) {
  .btn,
  a.btn,
  .header .easy-autocomplete-container .btns .btn {
    height: 38px;
    font-size: 14px;
    line-height: 1.5;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 19px;
  }
}
a.btn,
aa.btn,
a.header .easy-autocomplete-container .btns .btn {
  cursor: pointer;
  text-decoration: none;
}
.btn + .btn,
a.btn + .btn,
.header .easy-autocomplete-container .btns .btn + .btn {
  margin-left: 0.4em;
}
label {
  font-family: Roboto, sans-serif;
}
label.error {
  color: #c00;
}
span.check label {
  color: inherit;
  text-transform: inherit;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}
@media screen and (max-width: 819.98px) {
  span.check label {
    font-size: 12px;
  }
}
@media screen and (min-width: 819.98px) {
  span.check label {
    font-size: calc(12px + 3 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  span.check label {
    font-size: 15px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  span.check label {
    font-size: calc(12px + 3 * (78vw - 819.98px) / 480.02);
  }
}
span.check label .checkbox {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #ada295;
  flex-shrink: 0;
  position: relative;
}
@media screen and (max-width: 819.98px) {
  span.check label .checkbox {
    margin-right: 5px;
  }
}
@media screen and (min-width: 819.98px) {
  span.check label .checkbox {
    margin-right: calc(5px + 5 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  span.check label .checkbox {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  span.check label .checkbox {
    margin-right: calc(5px + 5 * (78vw - 819.98px) / 480.02);
  }
}
span.check label a {
  margin: 0 0.3em;
}
span.check input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
span.check input.error + label {
  color: #c00;
}
span.check input.error + label span {
  border-color: #c00;
}
span.check input:checked + label span {
  background-color: #555351;
}
span.check input:checked + label span:before {
  content: " ";
  position: absolute;
  top: 4px;
  left: 3px;
  width: 10px;
  height: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}
span.check input:disabled + label span {
  border: none;
  cursor: default;
}
span.check input:disabled + label span {
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 50%;
}
span.check input:disabled:checked + label span {
  background-size: 25px;
}
span.check.radio input:checked + label span:before,
span.check.radio input:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -4px;
  width: 8px;
  height: 8px;
  background-color: #ada295;
  border: 0;
  border-radius: 50%;
  transform: none;
}
span.check.radio input:checked + label span,
span.check.radio input:disabled + label span,
span.check.radio input:disabled:checked + label span {
  background-image: none;
}
.aside-tips.com {
  background: #fff;
  box-shadow: 0px 20px 40px rgba(173,162,149,0.502);
  border-radius: 10px;
  padding: 23px 30px;
}
.aside-tips.com h3 {
  color: #555351;
}
.aside-tips.com ul li {
  margin-bottom: 110px;
  margin-bottom: 40px;
}
.aside-tips.com ul li:last-child {
  margin-bottom: 0;
}
.aside-tips.com ul .item {
  display: block;
  box-shadow: 0px 10px 30px rgba(173,162,149,0.302);
  border-radius: 10px;
  overflow: hidden;
  color: #555351;
  font-weight: 600;
  transition: color 0.3s;
}
.aside-tips.com ul .item:hover {
  color: #cbaf65;
}
.aside-tips.com ul .item:hover .desc {
  text-decoration: none;
}
.aside-tips.com ul .desc {
  display: block;
  padding: 20px 15px;
  font-size: 20px;
  text-decoration: underline;
  font-family: Noto serif, serif;
}
.attachment.com {
  font-size: 13px;
  color: #a8a8a8;
}
.attachment.com h4.name {
  margin-bottom: 7px;
}
.attachment.com h4.name a {
  color: #ada295;
}
.attachment.com h4.name a:hover {
  color: #7d7b79;
}
.box-contact.com {
  width: 30%;
  margiin-right: 3%;
}
.box-contact.com.--person picture {
  display: inline-block;
  height: 84px;
  width: 84px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #707070;
  border-radius: 50%;
  box-shadow: 0px 3px 12px #ada295;
  overflow: hidden;
}
.box-contact.com.--person picture img {
  height: 100%;
  vertical-align: middle;
}
.box-contact.com h3 {
  color: #7d7b79;
}
.box-contact.com ul li {
  padding-left: 0;
}
.box-contact.com ul li:before {
  display: none;
}
.box-contact.com ul .ico {
  display: inline-block;
  width: 23px;
  height: 23px;
  border: 1px solid #808080;
  border-radius: 5px;
  margin-right: 9px;
  vertical-align: middle;
}
.box-contact.com .icon {
  width: 52px;
  height: 52px;
  marginn-bottom: 11px;
  fill: #ada295;
}
@media (max-width: 991.98px) {
  .box-contact.com {
    width: 50%;
  }
}
@media (max-width: 819.98px) {
  .box-contact.com {
    width: 100%;
    margin-bottom: 20px;
  }
}
.box-highlight.com {
  background: #ada295;
  background-size: cover;
  box-shadow: 0px 20px 30px rgba(173,162,149,0.502);
  border-radius: 10px;
  opacity: 1;
  color: #fff;
  padding: 55px 32px;
  text-align: center;
  font-family: Noto serif, serif;
  font-size: 18px;
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
}
@media screen and (max-width: 819.98px) {
  .box-highlight.com {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 819.98px) {
  .box-highlight.com {
    margin-bottom: calc(20px + 20 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .box-highlight.com {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .box-highlight.com {
    margin-bottom: calc(20px + 20 * (78vw - 819.98px) / 480.02);
  }
}
.box-highlight.com.--with-img a {
  color: #fff;
}
.box-highlight.com.--with-img a:hover {
  opacity: 0.8;
}
.box-highlight.com.--with-img:before {
  content: " ";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.533);
}
.box-highlight.com.blue {
  background-color: #182883;
  width: 70%;
  padding: 45px 11%;
  margin: 60px auto 0;
  border-radius: 10px;
  box-shadow: 0px 13px 26px rgba(173,162,149,0.302);
}
.box-highlight.com.blue.--w-100 {
  width: 100%;
}
.box-highlight.com.blue.--smaller-pad {
  padding: 30px 11%;
}
.box-highlight.com.blue .icon-mp {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  font-size: 12px;
  letter-spacing: 1.5px;
}
@media screen and (max-width: 819.98px) {
  .box-highlight.com.blue .icon-mp {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 819.98px) {
  .box-highlight.com.blue .icon-mp {
    margin-bottom: calc(20px + 10 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .box-highlight.com.blue .icon-mp {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .box-highlight.com.blue .icon-mp {
    margin-bottom: calc(20px + 10 * (78vw - 819.98px) / 480.02);
  }
}
.box-highlight.com.blue .icon-mp img {
  width: auto;
  max-width: 50px;
  position: relative;
  margin: 0 auto 10px;
  image-rendering: smooth;
  top: auto;
  left: auto;
  transform: none;
}
.box-highlight.com.blue .icon-anopa {
  text-align: center;
  margin-bottom: 10px;
}
@media screen and (max-width: 819.98px) {
  .box-highlight.com.blue .icon-anopa {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 819.98px) {
  .box-highlight.com.blue .icon-anopa {
    margin-bottom: calc(5px + 5 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .box-highlight.com.blue .icon-anopa {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .box-highlight.com.blue .icon-anopa {
    margin-bottom: calc(5px + 5 * (78vw - 819.98px) / 480.02);
  }
}
.box-highlight.com.blue .icon-anopa img {
  height: auto;
  max-height: 60px;
  position: relative;
  margin: 0 auto;
  image-rendering: smooth;
  top: auto;
  left: auto;
  transform: none;
}
.box-highlight.com .icon {
  width: 60px;
  height: 65px;
  display: inline-block;
}
.box-highlight.com a {
  color: #fff;
}
.box-highlight.com h2,
.box-highlight.com h3,
.box-highlight.com h4 {
  color: #fff;
  position: relative;
}
.box-highlight.com h2:not(.--no-line),
.box-highlight.com h3:not(.--no-line),
.box-highlight.com h4:not(.--no-line) {
  margin-bottom: 2.2em;
}
.box-highlight.com h2:not(.--no-line):before,
.box-highlight.com h3:not(.--no-line):before,
.box-highlight.com h4:not(.--no-line):before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1.1em;
  border: 2px solid #fff;
  width: 142px;
  height: 2px;
  margin-left: -71px;
  background: #fff;
}
.box-highlight.com .inner {
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.box-highlight.com img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media (max-width: 819.98px) {
  .box-highlight.com.blue {
    margin-top: 25px;
    width: 90%;
    padding: 20px;
  }
}
.downloads-wrapper.com {
  padding-bottom: 100px;
}
.downloads.com {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 2px;
}
.downloads.com .item {
  padding: 40px;
  color: #a8a8a8;
  background: #fff;
  position: relative;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  font-size: 13px;
  transition: all 0.5s;
}
.downloads.com .item img {
  max-height: 47px;
}
.downloads.com .item h3 {
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
  color: #ada295;
}
.downloads.com .item:hover {
  background: rgba(255,255,255,0.533);
}
.downloads.com .ico {
  display: inline-block;
  height: 42px;
  width: 42px;
  fill: currentColor;
}
@media (max-width: 819.98px) {
  .downloads.com {
    grid-template-columns: 1fr 1fr;
  }
  .downloads.com .item {
    padding: 20px;
  }
}
.figcaption.com {
  color: #a8a8a8;
  font-size: 13px;
  line-height: 18px;
  padding-top: 22px;
  margin-bottom: 40px;
}
@media screen and (max-width: 819.98px) {
  .figcaption.com {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 819.98px) {
  .figcaption.com {
    margin-bottom: calc(20px + 20 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .figcaption.com {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .figcaption.com {
    margin-bottom: calc(20px + 20 * (78vw - 819.98px) / 480.02);
  }
}
.filter-bar.com {
  padding: 16px 0;
  background: rgba(249,248,246,0.933);
  box-shadow: 0px -13px 26px rgba(173,162,149,0.302);
  border-bottom: 1px solid #eaeaea;
  color: #c3c1bf;
}
.filter-bar.com .container {
  display: flex;
  justify-content: space-between;
}
.filter-bar.com .cols {
  display: flex;
  width: 100%;
}
.filter-bar.com .btns {
  white-space: nowrap;
  margin-left: 20px;
}
.filter-bar.com .cancel {
  display: none;
  margin-right: 20px;
  font-weight: 600;
  font-family: Noto serif, serif;
}
.filter-bar.com .cancel.active {
  display: inline-block;
}
.filter-bar.com .title {
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.filter-bar.com .txt {
  font-size: 13px;
  font-family: Noto serif, serif;
}
.filter-bar.com .col-filter-bar {
  flex-basis: 20%;
  line-height: 1em;
  -webkit-line-clamp: 2;
}
.filter-bar.com .col-filter-bar + .col-filter-bar {
  margin-left: 20px;
}
@media (max-width: 819.98px) {
  .filter-bar.com .container {
    justify-content: flex-end;
  }
  .filter-bar.com .cols {
    display: none;
  }
}
.gallery-more.com {
  text-align: center;
  position: relative;
  z-index: 2;
  margin-bottom: 210px;
}
@media (max-width: 819.98px) {
  .gallery-more.com {
    margin-bottom: 40px;
  }
}
.gallery.com {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -17px;
  position: relative;
}
.gallery.com.--with-show-more {
  max-height: 360px;
  margin-bottom: -110px;
  transition: max-height 3s ease-out;
  overflow: hidden;
}
.gallery.com.--with-show-more.--open {
  max-height: 3000px;
  margin-bottom: 40px;
}
.gallery.com.--with-show-more.--open:before {
  display: none;
}
.gallery.com.--with-show-more:before {
  content: " ";
  width: 100%;
  height: 270px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: transparent linear-gradient(180deg, rgba(255,255,255,0) 0%, #efeeec 100%) 0% 0% no-repeat padding-box;
  pointer-events: none;
}
.gallery.com .item {
  width: 20%;
  padding: 0 17px;
  margin-bottom: 20px;
}
.gallery.com .item a {
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 13px 26px rgba(173,162,149,0.302);
  border-radius: 10px;
}
.gallery.com .item a img {
  transition: all 0.3;
}
.gallery.com .item a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0);
}
.gallery.com .item a:hover img {
  transform: scale(1.1);
}
.gallery.com .item a:hover:after {
  background: rgba(0,0,0,0.2);
}
@media (max-width: 819.98px) {
  .header form.form-search input.text {
    font-size: 11px;
  }
}
.icon-map.com {
  display: inline-block;
  width: 95px;
  height: 55px;
  vertical-align: middle;
  position: relative;
  margin-right: 25px;
}
.icon-map.com img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1199.98px) {
  .icon-map.com {
    display: block;
    text-align: center;
    margin: 0 auto 5px;
  }
}
.item-event.com,
.item-podcast.com,
.item-recommend.com {
  display: flex;
  min-height: 195px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 13px 26px rgba(173,162,149,0.302);
  margin-bottom: 30px;
  font-family: Noto serif, serif;
}
.item-event.com .img,
.item-podcast.com .img,
.item-recommend.com .img {
  overflow: hidden;
  position: relative;
  flex-basis: 36%;
}
.item-event.com .img img,
.item-podcast.com .img img,
.item-recommend.com .img img {
  max-width: none;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.item-event.com .info,
.item-podcast.com .info,
.item-recommend.com .info {
  padding: 15px 20px;
  flex-basis: 64%;
}
.item-event.com .date,
.item-podcast.com .date,
.item-recommend.com .date {
  font-size: 14px;
  letter-spacing: -0.28px;
  font-family: Roboto, sans-serif;
  margin-right: 10px;
}
.item-event.com .place,
.item-podcast.com .place,
.item-recommend.com .place {
  font-size: 11px;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  letter-spacing: 2px;
  color: #ada295;
  font-weight: normal;
}
.item-event.com .place a,
.item-podcast.com .place a,
.item-recommend.com .place a {
  color: #ada295;
}
.item-event.com .place a:hover,
.item-podcast.com .place a:hover,
.item-recommend.com .place a:hover {
  color: #555351;
}
.item-event.com h3,
.item-podcast.com h3,
.item-recommend.com h3 {
  letter-spacing: -0.4px;
  -webkit-line-clamp: 2;
}
.item-event.com p,
.item-podcast.com p,
.item-recommend.com p {
  margin: 0;
}
.item-event.com .desc,
.item-podcast.com .desc,
.item-recommend.com .desc {
  -webkit-line-clamp: 2;
  font-size: 15px;
  line-height: 1.6em;
}
@media (max-width: 991.98px) {
  .item-event.com,
  .item-podcast.com,
  .item-recommend.com {
    min-height: calc(100% - 30px);
  }
}
@media (max-width: 819.98px) {
  .item-event.com,
  .item-podcast.com,
  .item-recommend.com {
    display: block;
    min-height: 0;
    margin-bottom: 20px;
    min-height: calc(100% - 20px);
  }
}
@media (max-width: 991.98px) {
  .item-event.com.show-thumb,
  .item-podcast.com.show-thumb,
  .item-recommend.com.show-thumb {
    flex-direction: column;
  }
  .item-event.com.show-thumb .img,
  .item-podcast.com.show-thumb .img,
  .item-recommend.com.show-thumb .img {
    flex-basis: 100%;
  }
  .item-event.com.show-thumb .img img,
  .item-podcast.com.show-thumb .img img,
  .item-recommend.com.show-thumb .img img {
    position: relative;
    width: 100%;
    height: 150px;
  }
}
.item-podcast.com .img {
  flex-basis: 17.53%;
}
.item-podcast.com .info {
  flex-basis: 82.47%;
}
.item-podcast.com .desc {
  -webkit-line-clamp: 3;
}
.item-podcast.com .btn {
  margin: 1.25rem 0 0;
  min-width: 170px;
}
@media (max-width: 991.98px) {
  .item-podcast.com .img {
    flex-basis: 36%;
  }
  .item-podcast.com .info {
    flex-basis: 64%;
  }
}
@media (max-width: 819.98px) {
  .item-podcast.com {
    display: flex;
    min-height: 0;
  }
  .item-podcast.com .img {
    display: inline-block;
  }
  .item-podcast.com .btn {
    min-width: 0;
  }
}
@media (max-width: 499.98px) {
  .item-podcast.com {
    flex-direction: column;
  }
  .item-podcast.com .img {
    flex-basis: 100%;
  }
  .item-podcast.com .img img {
    position: relative;
    width: 100%;
    height: 150px;
  }
}
.item-recommend.com {
  background: #555351;
  color: #fff;
}
@media (min-width: 991.98px) {
  .item-recommend.com {
    min-height: calc(100% - 30px);
  }
}
.item-recommend.com h3 {
  margin-bottom: 0.3em;
}
.item-recommend.com a {
  color: #f9f8f6;
}
.item-recommend.com .place a {
  color: #f9f8f6;
}
.item-recommend.com .place a:hover {
  color: #ada295;
}
.item-recommend.com .tag-list.com ul li {
  color: #ada295;
  background: #676563;
}
@media (max-width: 499.98px) {
  .item-recommend.com {
    flex-direction: column;
  }
  .item-recommend.com .img {
    flex-basis: 100%;
  }
  .item-recommend.com .img img {
    position: relative;
    width: 100%;
    height: 150px;
  }
}
@media (max-width: 819.98px) {
  .item-recommend.com {
    display: flex;
    min-height: 0;
  }
  .item-recommend.com .img {
    display: inline-block;
  }
}
.main-nav {
  margin: 0 10px;
  text-align: center;
}
.main-nav ul {
  margin: 0 -12px;
  display: flex;
}
.main-nav ul li {
  padding: 0 12px;
  margin: 0;
  background: none;
}
.main-nav ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
}
.main-nav ul li a:after {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  background: #fff;
  transform: translateX(-50%);
  transition: all 0.3;
}
.main-nav ul li a:hover {
  color: #fff;
}
.main-nav ul li a:hover:after {
  width: 100%;
}
.main-nav ul li.menu-active a:after {
  width: 100%;
}
.map-filter.com > .row {
  align-items: self-start;
}
.map-filter.com h4 {
  color: #ada295;
}
.map-filter.com label {
  font-size: 15px;
  font-weight: normal;
  font-family: Noto serif, serif;
  color: #555351;
}
.map-filter.com .wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.map-filter.com .wrap span.check {
  flex-basis: 50%;
}
.map-filter.com .col-filter {
  margin-bottom: 40px;
}
.map-filter.com .mobile-only {
  display: none;
}
.map-filter.com .--with-icons span.check {
  width: 100%;
  padding-right: 20px;
  margin: 5px 0;
  font-family: Noto serif, serif;
}
.map-filter.com .--with-icons span.check .icon {
  width: 25px;
  height: 25px;
  margin-left: -5px;
  margin-right: 5px;
}
.map-filter.com .--with-icons span.check:hover label {
  background: #fff;
}
.map-filter.com .--with-icons span.check input:checked + label {
  background: #ada295;
}
.map-filter.com .--with-icons span.check .checkbox {
  margin-bottom: -2px;
}
.map-filter.com .--with-icons label {
  -webkit-line-clamp: 1;
  width: 100%;
  background: transparent;
  border-radius: 30px;
  padding: 6px 10px 6px;
  transition: background 0.5s;
}
@media (max-width: 819.98px) {
  .map-filter.com .mobile-only {
    display: block;
  }
  .map-filter.com .--with-icons span.check {
    text-align: center;
    background: none;
    border-radius: 0;
    margin: 0;
    padding: 1px;
    font-size: 12px;
  }
  .map-filter.com .--with-icons span.check .icon {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
  }
  .map-filter.com .--with-icons span.check label {
    padding: 15px 0 0;
    background: rgba(255,255,255,0.8);
    border-radius: 0;
  }
  .map-filter.com .--with-icons .checkbox {
    display: none;
  }
}
.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: none;
}
.mont-select.com {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}
.mont-select.com h3 {
  margin: 0;
}
.mont-select.com select {
  width: 150px;
  vertical-align: middle;
  margin-left: 10px;
}
@media (max-width: 819.98px) {
  .mont-select.com .wrap label {
    display: none;
  }
}
.pamatka-detail.com {
  display: flex;
}
.pamatka-detail.com .panel-left {
  box-sizing: padding-box;
  width: 50%;
  padding: 44px 95px 0;
}
.pamatka-detail.com .panel-left h1 {
  color: #4c4740;
  line-height: 1.1em;
}
.pamatka-detail.com .desc {
  font-size: 18px;
  font-family: Noto serif, serif;
  line-height: 26px;
  margin-bottom: 40px;
}
.pamatka-detail.com .type-place-icons.com,
.pamatka-detail.com .properties.com {
  color: #ada295;
  font-family: Noto serif, serif;
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 45px;
  margin-bottom: 40px;
}
.pamatka-detail.com .properties.com,
.pamatka-detail.com .time-table {
  padding-bottom: 25px;
}
.pamatka-detail.com .time-table {
  transition: opacity 1s;
}
.pamatka-detail.com .time-table.--hidden {
  height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0;
}
.pamatka-detail.com .time-table table {
  margin-bottom: 40px;
}
.pamatka-detail.com .time-table th,
.pamatka-detail.com .time-table td {
  font-family: Noto serif, serif;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  font-size: 15px;
}
.pamatka-detail.com .time-table tr:first-child {
  border-top: 1px solid #c7c7c7;
}
.pamatka-detail.com .time-table th {
  color: #ada295;
  background: transparent;
  font-weight: normal;
}
.pamatka-detail.com .time-table td {
  font-size: 15px;
}
.pamatka-detail.com .time-table td:first-child {
  font-weight: normal;
}
.pamatka-detail.com .panel-right {
  width: 50%;
}
.pamatka-detail.com .panel-right .map {
  background: rgba(0,128,0,0.212);
  height: 605px;
  position: relative;
}
.pamatka-detail.com .panel-right .map:before {
  content: " ";
  width: 100%;
  height: 270px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: transparent linear-gradient(180deg, rgba(255,255,255,0) 0%, #efeeec 100%) 0% 0% no-repeat padding-box;
}
.pamatka-detail.com .panel-right .contact {
  position: relative;
  z-index: 2;
  width: 70%;
  padding: 45px 11%;
  margin: -150px auto 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 13px 26px rgba(173,162,149,0.302);
}
.pamatka-detail.com .panel-right .contact ul {
  margin: 0;
}
.pamatka-detail.com .panel-right .contact li:before {
  display: none;
}
.pamatka-detail.com .panel-right .contact a {
  text-decoration: underline;
}
@media (max-width: 819.98px) {
  .pamatka-detail.com {
    display: block;
  }
  .pamatka-detail.com .panel-left {
    width: 100%;
    padding: 40px 15px;
  }
  .pamatka-detail.com .panel-right {
    width: 100%;
    padding-bottom: 40px;
  }
  .pamatka-detail.com .panel-right .map {
    height: 300px;
  }
  .pamatka-detail.com .panel-right .contact {
    margin-top: 0;
    width: 90%;
    padding: 20px;
  }
}
.pamatka-no-category.com {
  background: #ada295;
  color: #4c4740;
  font-family: Noto serif, serif;
  margin-bottom: 40px;
}
.pamatka-no-category.com .container {
  display: flex;
}
.pamatka-no-category.com .panel-left {
  flex-shrink: 1;
  width: 323px;
  height: 323px;
  position: relative;
  overflow: hidden;
  margin-right: 80px;
  margin: 55px 0;
}
.pamatka-no-category.com .panel-left img {
  max-width: none;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.pamatka-no-category.com .panel-right {
  padding: 55px 0 55px 80px;
  color: #555351;
}
.pamatka-no-category.com .panel-right h1 {
  color: #4c4740;
  line-height: 1.1em;
  margin-bottom: 10px;
  color: #555351;
}
.pamatka-no-category.com .panel-right .desc {
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  margin-bottom: 35px;
}
@media screen and (max-width: 819.98px) {
  .pamatka-no-category.com .panel-right .desc {
    font-size: 14px;
  }
}
@media screen and (min-width: 819.98px) {
  .pamatka-no-category.com .panel-right .desc {
    font-size: calc(14px + 4 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .pamatka-no-category.com .panel-right .desc {
    font-size: 18px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .pamatka-no-category.com .panel-right .desc {
    font-size: calc(14px + 4 * (78vw - 819.98px) / 480.02);
  }
}
.pamatka-no-category.com .type-place-icons.com {
  padding-bottom: 40px;
}
.pamatka-no-category.com .btn {
  margin-right: 30px;
}
.pamatka-no-category.com .btn:last-child {
  margin-right: 0;
}
@media (max-width: 1199.98px) {
  .pamatka-no-category.com {
    padding-top: 40px;
  }
  .pamatka-no-category.com .container {
    display: block;
  }
  .pamatka-no-category.com .panel-left {
    width: 100%;
    height: auto;
    margin: 0;
  }
  .pamatka-no-category.com .panel-left img {
    position: static;
    transform: none;
    max-width: 100%;
    height: auto;
  }
  .pamatka-no-category.com .panel-right {
    padding: 20px 0;
    text-align: center;
    padding: 40px 0 40px 40px;
  }
  .pamatka-no-category.com .btns {
    text-align: center;
  }
  .pamatka-no-category.com .btns .btn {
    margin-right: 5px;
  }
  .pamatka-no-category.com .btns .btn:last-child {
    margin-right: 0;
  }
  .pamatka-no-category.com .type-place-icons.com {
    text-align: center;
    justify-content: center;
  }
}
@media (max-width: 819.98px) {
  .pamatka-no-category.com {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pamatka-no-category.com .panel-right {
    padding: 20px 0;
  }
  .pamatka-no-category.com .btns .btn {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 0;
  }
}
.perex.com {
  font-family: Noto serif, serif;
  font-size: 18px;
  font-weight: 500;
}
.properties.com {
  padding: 25px 0;
}
.properties.com ul {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}
.properties.com li {
  display: inline-block;
  flex-basis: 50%;
  margin-bottom: 20px;
}
.properties.com .icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
@media (max-width: 819.98px) {
  .properties.com {
    padding-top: 0;
  }
  .properties.com ul {
    margin: 0 -20px;
    justify-content: center;
  }
  .properties.com li {
    flex-basis: 33.3%;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.2em;
    padding: 0.6rem 0.8rem;
    margin: 0;
  }
  .properties.com li .icon {
    display: block;
    margin: 0 auto;
    width: 2.8rem;
    height: 2.8rem;
  }
}
.info.com {
  display: inline-block;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  vertiacl-align: center;
  background: #7d7b79;
  color: #fff;
  cursor: help;
}
.social-nav {
  margin-right: 10px;
}
.social-nav ul {
  margin: 0 -5px;
  display: flex;
}
.social-nav ul li {
  margin: 0;
  padding: 0 5px;
}
.social-nav ul li a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
}
.social-nav ul li a:hover {
  background: #fff;
  color: #7a6e60;
}
.social-nav ul li a .icon {
  width: 15px;
  height: 15px;
}
.table-info.com {
  color: #a8a8a8;
  font-size: 13px;
  line-height: 18px;
}
.tag-list.com {
  display: inline-block;
  vertical-align: middle;
}
.tag-list.com ul {
  margin: 0;
}
.tag-list.com ul li {
  display: inline-block;
  color: #7d7b79;
  background: #efedec;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  padding: 4px 7px;
  line-height: 12px;
  vertical-align: middle;
  margin: 5px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
}
.tag-list.com ul li:first-child {
  margin-left: 0;
}
.tag-list.com ul li:last-child {
  margin-right: 0;
}
.teaser {
  margin: 0;
  position: relative;
  z-index: 50;
  box-shadow: 0px 30px 40px #c4c4c4 80;
}
.teaser:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
}
.teaser-content {
  height: 757px;
  min-height: 500px;
  max-height: 100vh;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.teaser-content h1 {
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}
.teaser-content .medium {
  width: 100%;
  max-width: 660px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 50;
}
.teaser-content .medium:last-child {
  margin-bottom: 0;
}
.teaser-content .desc {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 15px 90px 15px;
  text-align: right;
  z-index: 50;
}
.teaser-content .desc a {
  font-size: 14px;
  letter-spacing: 1.12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-decoration: underline;
  position: relative;
  display: inline-block;
  padding: 15px 23px 15px 71px;
  background: rgba(255,255,255,0.333);
  border-radius: 70px;
  text-decoration: none;
}
.teaser-content .desc .icon {
  margin: 0 10px 9px 0;
  position: relative;
  top: 2px;
}
@media (max-width: 819.98px) {
  .teaser-content .desc a {
    font-size: 12px;
    padding: 10px 15px 10px 50px;
  }
}
@media (max-width: 575.98px) {
  .teaser-content {
    height: 420px;
    min-height: 420px;
  }
  .teaser-content .desc {
    padding-bottom: 52px;
  }
}
.thumb.com img,
.thumb.com .img {
  background: #fff;
  box-shadow: 0px 30px 40px rgba(173,162,149,0.502);
  border-radius: 10px;
}
.thumb.com.--big .img {
  max-width: none;
  width: 715px;
  height: 473px;
}
.thumb.com.--big figcaption {
  width: 315px;
  padding-left: 40px;
}
figcaption {
  color: #a8a8a8;
  font-size: 13px;
  max-width: 330px;
  line-height: 18px;
  padding-top: 22px;
}
.tip-detail.com {
  margin-bottom: 110px;
  background: #fff;
  box-shadow: 0px 30px 40px rgba(173,162,149,0.502);
  font-family: Noto serif, serif;
  position: relative;
  border-radius: 10px;
}
.tip-detail.com .wrapper {
  display: flex;
  min-height: 390px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.tip-detail.com .panel-left {
  width: 50%;
  padding: 40px 40px 20px;
}
.tip-detail.com .panel-left .desc {
  font-size: 18px;
  margin-bottom: 32px;
  border-bottom: 1px solid #707070;
}
.tip-detail.com .panel-right {
  width: 50%;
  font-size: 15px;
}
.tip-detail.com .map-wrap {
  height: 100%;
  position: relative;
}
.tip-detail.com .map-wrap:before {
  content: " ";
  background: transparent linear-gradient(180deg, rgba(0,0,0,0) 0%, #000 100%) 0% 0% no-repeat padding-box;
  opacity: 0.3;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  z-index: 2;
}
.tip-detail.com .map-buttons {
  position: absolute;
  z-index: 3;
  bottom: 30px;
  left: 40px;
}
.tip-detail.com .map-buttons .btn + .btn {
  margin-left: 35px;
}
.tip-detail.com .properties.com {
  color: #ada295;
  margin-bottom: 0;
}
.tip-detail.com .scroll-icon {
  display: none;
  position: absolute;
  z-index: 4;
  bottom: -25px;
  left: 50%;
  width: 63px;
  height: 91px;
  border-radius: 32px;
  backdrop-filter: blur(16px);
  transform: translateX(-50%);
}
.tip-detail.com .scroll-icon:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 37px;
  margin-left: -18px;
  margin-top: -26px;
  height: 53px;
  background: #555351;
  border-radius: 32px;
  opacity: 1;
}
@media (max-width: 819.98px) {
  .tip-detail.com {
    margin-bottom: 40px;
  }
  .tip-detail.com .wrapper {
    display: block;
    min-height: auto;
    overflow: visible;
  }
  .tip-detail.com .panel-left,
  .tip-detail.com .panel-right {
    width: 100%;
  }
  .tip-detail.com .panel-left {
    padding: 30px;
  }
  .tip-detail.com .panel-right {
    height: 400px;
  }
  .tip-detail.com .scroll-icon {
    display: none;
  }
}
.tooltip.com {
  position: relative;
  border-bottom: 1px dotted #000;
}
.tooltip.com:before {
  content: attr(data-tooltip);
  position: absolute;
  width: 100px;
  background-color: #062b45;
  color: #fff;
  text-align: center;
  padding: 10px;
  line-height: 1.2;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  font-size: 0.75em;
}
.tooltip.com:after {
  content: "";
  position: absolute;
  bottom: 75%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  opacity: 0;
  transition: opacity 0.6s;
  border-color: #062b45 transparent transparent transparent;
}
.tooltip.com:hover:before,
.tooltip.com:hover:after {
  opacity: 1;
}
.type-place-icons.com {
  display: flex;
}
.type-place-icons.com .info {
  font-size: 15px;
}
@media screen and (max-width: 819.98px) {
  .type-place-icons.com .info {
    font-size: 12px;
  }
}
@media screen and (min-width: 819.98px) {
  .type-place-icons.com .info {
    font-size: calc(12px + 3 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .type-place-icons.com .info {
    font-size: 15px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .type-place-icons.com .info {
    font-size: calc(12px + 3 * (78vw - 819.98px) / 480.02);
  }
}
.type-place-icons.com .info:first-child {
  margin-right: 60px;
}
.type-place-icons.com .icon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  margin-right: 20px;
  margin-top: -5px;
}
.type-place-icons.com .icon.--country {
  fill: transparent;
  stroke: currentColor;
}
@media (max-width: 991.98px) {
  .type-place-icons.com {
    text-align: center;
    justify-content: center;
  }
  .type-place-icons.com .icon {
    display: block;
    margin: 0 auto;
  }
  .type-place-icons.com .info {
    margin: 0 40px;
  }
  .type-place-icons.com .info:first-child {
    margin-right: 40px;
  }
}
.video-thumb.com {
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  overflow: hidden;
  border-radius: 10px;
}
.video-thumb.com:after,
.video-thumb.com:before {
  content: "";
  position: absolute;
}
.video-thumb.com:after {
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 30px solid transparent;
  border-left: 55px solid #645d52;
  border-bottom: 30px solid transparent;
  margin-left: 10px;
}
.video-thumb.com:before {
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: rgba(255,255,255,0.85);
  transform: translate(-50%, -50%);
  margin-left: 0;
}
.video-thumb.com:hover:before {
  background: #fff;
  color: #182883;
}
.video-thumb.com video {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}
.video-thumb.com .mute {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.video-thumb.com.--playing {
  position: relative;
  overflow: hidden;
}
.video-thumb.com.--playing:before,
.video-thumb.com.--playing:after {
  display: none;
}
.video-thumb.com.--playing video {
  display: block;
}
.video-thumb.com.--playing .mute {
  opacity: 1;
}
.video-thumb.com.--muted .mute {
  background: #f00;
}
@media (max-width: 819.98px) {
  .video-thumb.com:before {
    transform: scale(0.7);
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.95;
  }
}
body.bg-forrest > .content {
  background: #f9f8f6 url("../img/bg-forrest.jpg") no-repeat 50% 0;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.selected {
  font-wight: bold;
}
.text-center {
  text-align: center;
}
.w-100 {
  width: 100%;
}
.svg-map {
  max-width: 657px;
}
.smap-wrapper.--in-tab {
  margin-bottom: 0;
}
@media (max-width: 819.98px) {
  .smap-wrapper.--in-tab {
    height: 300px;
  }
}
@media (max-width: 819.98px) {
  .svg-map-wrapper.com {
    display: none;
  }
}
.svg-map.com {
  margin-bottom: 55px;
}
.svg-map.com path.area {
  fill: #eae4de;
}
.svg-map.com path.area.active {
  fill: #555351;
}
.svg-map.com path.area:hover:not(.active) {
  fill: #fff;
}
.svg-map.com .labels .item.active .num {
  color: #555351;
  background: #f1ede9;
}
.svg-map.com .labels .item .num {
  display: block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  background: #555351;
  border-radius: 50%;
  color: #fff;
  margin: 0 auto;
}
@media (max-width: 819.98px) {
  .svg-map.com {
    display: none;
  }
}
body .smap .card-header {
  border-radius: 10px;
}
body .smap .card-header .img,
body .smap .card-header img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
body .smap .card-header .text .title {
  font-size: 19px;
  text-align: center;
  font-family: Noto serif, serif;
}
body .smap .card .close {
  background: #555351;
}
body .smap .card .close:after {
  color: #fff;
}
.teaser-content .desc a {
  transition: all 0.5s;
  padding: 5px 23px;
}
.teaser-content .desc a:hover {
  background: rgba(255,255,255,0.4);
}
.teaser-content .desc .icon {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}
@media (max-width: 819.98px) {
  .teaser-content .desc {
    text-align: center;
  }
}
.container + .footer {
  margin-top: 60px;
}
@media screen and (max-width: 819.98px) {
  .container + .footer {
    margin-top: 40px;
  }
}
@media screen and (min-width: 819.98px) {
  .container + .footer {
    margin-top: calc(40px + 20 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .container + .footer {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .container + .footer {
    margin-top: calc(40px + 20 * (78vw - 819.98px) / 480.02);
  }
}
.footer {
  margin-top: 100px;
  margin-top: 100px;
}
@media screen and (max-width: 819.98px) {
  .footer {
    margin-top: 40px;
  }
}
@media screen and (min-width: 819.98px) {
  .footer {
    margin-top: calc(40px + 60 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .footer {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .footer {
    margin-top: calc(40px + 60 * (78vw - 819.98px) / 480.02);
  }
}
.footer h3 {
  text-transform: none;
  font-size: 20px;
  min-height: 2em;
}
@media screen and (max-width: 819.98px) {
  .footer h3 {
    font-size: 15px;
  }
}
@media screen and (min-width: 819.98px) {
  .footer h3 {
    font-size: calc(15px + 5 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .footer h3 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .footer h3 {
    font-size: calc(15px + 5 * (78vw - 819.98px) / 480.02);
  }
}
.footer a {
  text-decoration: underline;
}
.footer a:hover {
  color: #555351;
}
.footer .foot-item input {
  display: none;
}
.footer .foot-item label {
  padding-left: 0;
}
@media (max-width: 819.98px) {
  .footer .foot-item h3 {
    margin-bottom: 1px;
    min-height: 0;
    padding: 10px 20px;
    border-bottom: 1px solid #eaeaea;
  }
  .footer .foot-item ul {
    max-height: 0;
    overflow: hidden;
    transition: all 1s;
    padding: 0 20px;
    margin: 0;
    background: #f8f8f8;
  }
  .footer .foot-item input:checked + ul {
    max-height: 1000px;
    padding: 20px;
    margin-bottom: 2em;
  }
}
.section-head {
  margin-bottom: 4em;
}
@media (max-width: 819.98px) {
  .section-head {
    margin-bottom: 2em;
  }
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 51;
  top: -1px;
}
@media (max-width: 819.98px) {
  .sticky {
    position: static;
  }
}
form.form-filter-events {
  transition: all 0.3s;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 20px;
}
form.form-filter-events.isSticky {
  background: rgba(249,248,246,0.933);
  box-shadow: 0px 13px 26px rgba(173,162,149,0.302);
  border-bottoom: 1px solid #c7c7c7;
}
.subscribe.com {
  padding: 40px 0 30px;
}
.subscribe.com .icon.icon-zamek {
  width: 65px;
  height: 65px;
  fill: #ada295;
}
.subscribe.com p.h4 {
  margin-bottom: 5px;
}
.subscribe.com .head {
  width: 100%;
}
.subscribe.com .desc {
  max-width: 100%;
}
.subscribe.com .text {
  flex-flow: 1;
}
.subscribe.com input[type="email"] {
  flex-grow: 1;
}
.subscribe.com input[type="email"]::-webkit-input-placeholder {
  color: #555351;
}
.subscribe.com input[type="email"]:-moz-placeholder {
  color: #555351;
}
.subscribe.com input[type="email"]::-moz-placeholder {
  color: #555351;
}
.subscribe.com input[type="email"]:-ms-input-placeholder {
  color: #555351;
}
.subscribe.com input[type="email"].placeholder {
  color: #555351;
}
.subscribe.com input[type="checkbox"]:not(:checked) + label span {
  background: #f0eee9;
  border: 1px solid #f0eee9;
}
.subscribe.com .check-agreement {
  font-size: 13px !important;
  font-family: Roboto, sans-serif;
  color: #93918e;
}
.subscribe.com .btn {
  min-width: 170px;
}
@media (max-width: 819.98px) {
  .subscribe.com .btn {
    min-width: 0;
  }
}
.sights a h3 {
  text-decoration: underline;
}
.sights .item-list {
  justify-content: center;
}
.sights .item-inner {
  position: relative;
}
.sights .item-inner .tag-list {
  position: absolute;
  z-index: 21;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}
.sights .item-inner .tag-list .tag {
  display: block;
}
.sights .item-inner .tag-list .tag .js-qtip {
  display: block;
}
.sights .item-inner .tag-list .tag-crown {
  width: 32px;
  height: 32px;
  background: url("../img/svg/crown-tag.svg") 50% 50% no-repeat;
  background-size: 100% 100%;
}
.sights .item-inner .tag-list .tag-crown .js-qtip {
  height: 32px;
  cursor: help;
}
.sights .item-inner .tag-list .tag-anopa .js-qtip {
  background-color: #fff;
  font-size: 13px;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: #c00;
  line-height: 23px;
  padding: 0 0.5rem;
  border-radius: 4px;
  cursor: help;
}
@media (max-width: 991.98px) {
  .sights .item-inner {
    max-width: 250px;
  }
}
.header.alt .easy-autocomplete-container {
  top: 101px;
}
.header .easy-autocomplete-container {
  top: 135px;
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.278);
  background: #fff;
}
.header .easy-autocomplete-container ul {
  max-width: 1070px;
  padding: 50px 20px 20px;
  border-top: 0;
  box-shadow: none;
  margin: 0 auto;
  top: 0;
}
.header .easy-autocomplete-container ul:before {
  display: none;
}
.header .easy-autocomplete-container ul li {
  max-width: 33.33%;
}
.header .easy-autocomplete-container .eac-category {
  color: #c9c5c0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.12px;
  border-bottom: 1px solid #eaeaea !important;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.header .easy-autocomplete-container .eac-item {
  padding-right: 50px;
}
.header .easy-autocomplete-container .eac-item .img img {
  width: 63px;
  height: 63px;
  border-radius: 16px;
}
.header .easy-autocomplete-container .eac-item a {
  font-family: Noto serif, serif;
  letter-spacing: -0.4px;
  line-height: 1.6em;
}
.header .easy-autocomplete-container .eac-item a.big {
  font-size: 20px;
  font-weight: bold;
}
.header .easy-autocomplete-container b {
  padding: 0.3em;
  background-color: #ada295;
  color: #fff;
  text-decoration: none;
}
.header .easy-autocomplete-container .btns {
  text-align: center;
  display: block;
  padding: 0 0 50px;
}
.header .easy-autocomplete-container .btns .btn {
  width: auto;
  height: auto;
  margin: 0;
  line-height: 1.4em;
}
@media (max-width: 819.98px) {
  .header .easy-autocomplete-container {
    display: none;
  }
}
.spacer,
.spacer.com {
  height: 5rem;
}
.spacer.huge,
.spacer.com.huge {
  height: 10rem;
}
.spacer.big,
.spacer.com.big {
  height: 6.25rem;
}
.spacer.medium,
.spacer.com.medium {
  height: 3.75rem;
}
.spacer.small,
.spacer.com.small {
  height: 2.5rem;
}
select {
  min-width: 80px;
  background: #fff url("../img/svg/ico-down.svg") no-repeat;
  background-position: right 15px center;
  background-size: 10px;
}
.tabs.tabs-std + .block.bg-white.alpha.omega {
  margin-top: 0;
}
.block.bg-white.alpha.omega {
  margin-top: 80px;
}
@media screen and (max-width: 819.98px) {
  .block.bg-white.alpha.omega {
    margin-top: 40px;
  }
}
@media screen and (min-width: 819.98px) {
  .block.bg-white.alpha.omega {
    margin-top: calc(40px + 40 * (100vw - 819.98px) / 480.02);
  }
}
@media screen and (min-width: 1300px) {
  .block.bg-white.alpha.omega {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1100px) and (max-height: 780px) {
  .block.bg-white.alpha.omega {
    margin-top: calc(40px + 40 * (78vw - 819.98px) / 480.02);
  }
}
.block.bg-white.alpha.omega + footer {
  margin-top: 0;
}
.section-head.alt .tag-list.com {
  margin-top: 1.5em;
}
.section-head.alt .tag-list.com li {
  background: #fff;
}
ul.contact-list li a {
  text-decoration: underline;
}
input[type=date] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  line-height: 1.8em;
}
@media (max-width: 819.98px) {
  .pimcore_area_actions .col-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (max-width: 819.98px) {
  .footer .f_2 .inner {
    border: 0;
  }
}
.icons-kraj-list .type-place-icons.com {
  margin-bottom: 40px;
}
.btn.ico-arr-down {
  background-image: url("../img/svg/ico-arr-down.svg");
  background-repeat: no-repeat;
  background-position: 20px 50%;
  padding-left: 40px;
}
.btn.ico-arr-down.golden {
  background-image: url("../img/svg/ico-arr-down-white.svg");
}
.btn.small.ext-link {
  background-image: url("../img/svg/external-link.svg");
  background-repeat: no-repeat;
  background-position: 85% 45%;
  padding-right: 40px;
  background-size: auto 20px;
}
.teaser.--gallery:after {
  display: none;
}
.teaser.--gallery img {
  width: 100%;
}
.teaser.--gallery .slider-nav ol {
  background: rgba(255,255,255,0.8);
  padding: 0.5rem;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}
.teaser.--gallery .slider-nav li {
  margin: 0;
}
.teaser.--gallery .slider-nav li a {
  width: 1rem;
  height: 1rem;
  border-width: 4px;
  background-color: rgba(51,51,51,0.4);
  transition: all 0.5s;
}
.teaser.--gallery .slider-nav li a.current {
  background: #fff;
  border-color: #7a6e60;
  border-width: 6px;
}
.box-slider-wrapper {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.box-slider-wrapper .box-highlight.com {
  margin: 0 25px 46px;
}
.box-slider-wrapper.--full {
  max-width: 100%;
}
.teaser.--gallery:after {
  display: none;
}
.teaser.--gallery .slider-nav ol {
  background: rgba(255,255,255,0.8);
  padding: 0.5rem;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}
.teaser.--gallery .slider-nav li {
  margin: 0;
}
.teaser.--gallery .slider-nav li a {
  width: 1rem;
  height: 1rem;
  border-width: 4px;
  background-color: rgba(51,51,51,0.4);
}
.teaser.--gallery .slider-nav li a.current {
  background: #fff;
  border-color: #7a6e60;
  border-width: 6px;
}
form.std .radio,
.form.std .radio {
  position: static;
}
.warning {
  display: inline-block;
  background: #fff;
  padding: 0.6em 1em 0.5em;
  margin-bottom: 35px;
  border-radius: 10px;
  color: #e83a3a;
  line-height: 1.2em;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.15);
  background: linear-gradient(0, #fffcfc 5%, #f5e3e3);
  border: 2px solid #fff;
}
.warning .icon {
  margin-right: 0.5em;
}
.warning.--block {
  padding: 1em;
}
.warning.--block .icon {
  display: block;
  margin: 0 auto 5px;
  width: 30px;
  height: 30px;
}
@media (max-width: 819.98px) {
  .warning {
    padding: 1em 50px;
  }
  .warning .icon {
    display: block;
    margin: 0 auto 4px;
  }
}
